import { useRouter } from "next/router";
import { useEffect } from "react";

import useAnalytics from "../hooks/useAnalytics";

const AnalyticsPage = () => {
    const router = useRouter();
    const analytics = useAnalytics();

    useEffect(() => {
        analytics?.page();
    }, [router.asPath]);

    return null;
};

export default AnalyticsPage;
