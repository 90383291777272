import { isBefore, isWithinInterval } from "date-fns";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { interpolate, Route } from "@sol/routing";
import { Arrow } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { ClassroomsList } from "./ClassroomsList";
import { HomeCard, HomeCardDescription } from "./HomeCard";

export function ManagerHome() {
    const { classrooms = [], isLoading } = useAuthenticatedUserClassrooms();
    const [t] = useTranslation();

    return (
        <div className="flex h-full w-full flex-row items-stretch gap-4">
            <div className="w-1/2">
                <HomeCard title={t("component.home.manager.classrooms.title")} className="h-full">
                    <HomeCardDescription>{t("component.home.manager.classrooms.description")}</HomeCardDescription>
                    <ClassroomsList
                        isLoading={isLoading}
                        classrooms={classrooms.filter(c => {
                            const start = new Date(c.startDate);
                            const end = new Date(c.endDate);
                            // DEBT: data consistency should be ensured by the API
                            // here sometimes we encounter endDate before startDate
                            // this code mitigate the inconsistency
                            const interval = !isBefore(end, start)
                                ? {
                                      start,
                                      end,
                                  }
                                : {
                                      start: end,
                                      end: start,
                                  };

                            return isWithinInterval(new Date(), interval);
                        })}
                    />
                </HomeCard>
            </div>

            <div className="flex w-1/2 flex-col gap-4">
                <HomeCard
                    title={t("component.home.manager.follow-ups.title")}
                    className="min-h-0 flex-1"
                    image={<img src="/static/home/growth.svg" className="object-cover object-right-top" alt="" />}
                >
                    <HomeCardDescription label={t("component.home.manager.follow-ups.label")}>
                        {t("component.home.manager.follow-ups.description")}
                    </HomeCardDescription>
                    <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                        <Button
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.manager.follow-ups.cta.title")}
                            aria-label={t("component.home.manager.follow-ups.cta.title")}
                        >
                            {t("component.home.manager.follow-ups.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
                <HomeCard title={t("component.home.manager.admin.title")} image={<div />}>
                    <HomeCardDescription label={t("component.home.manager.admin.label")}>
                        {t("component.home.manager.admin.description")}
                    </HomeCardDescription>
                    <Link href={interpolate(Route.ADMIN, {})} passHref>
                        <Button
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.manager.admin.cta.title")}
                            aria-label={t("component.home.manager.admin.cta.title")}
                        >
                            {t("component.home.manager.admin.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
            </div>
        </div>
    );
}
