import { isWithinInterval, isBefore } from "date-fns";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { interpolate, Route } from "@sol/routing";
import { Arrow } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { ClassroomsList } from "./ClassroomsList";
import { HomeCard, HomeCardDescription } from "./HomeCard";

export function TrainerHome() {
    const [t] = useTranslation();
    const { classrooms = [], isLoading } = useAuthenticatedUserClassrooms();

    return (
        <div className="grid h-full w-full grid-cols-2 grid-rows-11 gap-4">
            <HomeCard title={t("component.home.trainer.classrooms.title")} className="col-span-1 row-span-7">
                <ClassroomsList
                    classrooms={classrooms.filter(c => {
                        const start = new Date(c.startDate);
                        const end = new Date(c.endDate);
                        // DEBT: data consistency should be ensured by the API
                        // here sometimes we encounter endDate before startDate
                        // this code mitigate the inconsistency
                        const interval = !isBefore(end, start)
                            ? {
                                  start,
                                  end,
                              }
                            : {
                                  start: end,
                                  end: start,
                              };

                        return isWithinInterval(new Date(), interval);
                    })}
                    isLoading={isLoading}
                />
            </HomeCard>
            <HomeCard title={t("component.home.trainer.trainer-workspace.title")} className="row-span-11 pb-0">
                <div className="flex min-h-0 flex-1 flex-col justify-between gap-12">
                    <div className="flex flex-row gap-8">
                        <HomeCardDescription
                            label={t("component.home.trainer.trainer-workspace.label")}
                            className="flex-1"
                        >
                            {t("component.home.trainer.trainer-workspace.description")}
                        </HomeCardDescription>
                        <div className="min-h-0 flex-1">
                            <img className="h-full w-full" src="/static/home/analytics.svg" alt="" />
                        </div>
                    </div>
                    <div className="flex max-h-[480px] min-h-0 flex-1 flex-row gap-8">
                        <div className="flex basis-1/2 flex-col gap-4">
                            <HomeCardDescription label={t("component.home.trainer.trainer-workspace.briefs.label")}>
                                {t("component.home.trainer.trainer-workspace.briefs.description")}
                            </HomeCardDescription>
                            <Link href={interpolate(Route.TRAINER_WORKSPACE_BRIEFS, {})} passHref>
                                <Button
                                    size="small"
                                    className="self-end"
                                    iconPosition="end"
                                    icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                                    title={t("component.home.trainer.trainer-workspace.briefs.cta.title")}
                                    aria-label={t("component.home.trainer.trainer-workspace.briefs.cta.title")}
                                >
                                    {t("component.home.trainer.trainer-workspace.briefs.cta.label")}
                                </Button>
                            </Link>

                            <div className="min-h-0 flex-1">
                                <img
                                    className="h-full w-full object-cover object-top"
                                    src="/static/home/media.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="flex basis-1/2 flex-col gap-4">
                            <HomeCardDescription
                                label={t("component.home.trainer.trainer-workspace.professional-situations.label")}
                            >
                                {t("component.home.trainer.trainer-workspace.professional-situations.description")}
                            </HomeCardDescription>
                            <Link href={interpolate(Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION, {})} passHref>
                                <Button
                                    size="small"
                                    className="self-end"
                                    iconPosition="end"
                                    icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                                    title={t(
                                        "component.home.trainer.trainer-workspace.professional-situations.cta.title",
                                    )}
                                    aria-label={t(
                                        "component.home.trainer.trainer-workspace.professional-situations.cta.title",
                                    )}
                                >
                                    {t("component.home.trainer.trainer-workspace.professional-situations.cta.label")}
                                </Button>
                            </Link>

                            <div className="flex-1 overflow-hidden">
                                <img
                                    className="h-full w-full object-cover object-top"
                                    src="/static/home/launch.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </HomeCard>
            <HomeCard
                title={t("component.home.trainer.follow-ups.title")}
                className="row-span-4"
                image={<img src="/static/home/growth.svg" alt="" className="object-cover object-right-top" />}
            >
                <HomeCardDescription label={t("component.home.trainer.follow-ups.label")}>
                    {t("component.home.trainer.follow-ups.description")}
                </HomeCardDescription>
                <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                    <Button
                        size="small"
                        className="self-end"
                        iconPosition="end"
                        icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                        title={t("component.home.trainer.follow-ups.cta.title")}
                        aria-label={t("component.home.trainer.follow-ups.cta.title")}
                    >
                        {t("component.home.trainer.follow-ups.cta.label")}
                    </Button>
                </Link>
            </HomeCard>
        </div>
    );
}
