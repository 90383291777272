import Head from "next/head";
import React from "react";

import { appName } from "../constants/app";

type Props = {
    title?: string;
};

const MetaTitle = ({ title }: Props) => {
    return (
        <Head>
            <title>{title ? `${appName} - ${title}` : appName}</title>
        </Head>
    );
};

export default MetaTitle;
