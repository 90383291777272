import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import { forwardRef } from "react";
import styled from "styled-components";

export type ButtonProps = AntButtonProps;

const StyledAntButton = styled(AntButton)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: ${({ type }) => (type === "default" || type === undefined) && "var(--ant-color-primary)"};
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <StyledAntButton ref={ref} {...props} />;
});

Button.displayName = "Button";

export default Button;
