import Link from "next/link";
import { useTranslation } from "react-i18next";

import { interpolate, Route } from "@sol/routing";
import { Arrow } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { HomeCard, HomeCardDescription } from "./HomeCard";

export function TutorHome() {
    const [t] = useTranslation();

    return (
        <div className="flex h-full w-full flex-row items-stretch gap-4">
            <HomeCard title={t("component.home.tutor.follow-ups.title")} className="w-1/2">
                <HomeCardDescription label={t("component.home.tutor.follow-ups.label")}>
                    {t("component.home.tutor.follow-ups.description")}
                </HomeCardDescription>
                <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                    <Button
                        size="small"
                        className="self-end"
                        iconPosition="end"
                        icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                        title={t("component.home.tutor.follow-ups.cta.title")}
                        aria-label={t("component.home.tutor.follow-ups.cta.title")}
                    >
                        {t("component.home.tutor.follow-ups.cta.label")}
                    </Button>
                </Link>
                <div className="min-h-0 flex-1">
                    <img src="/static/home/growth.svg" className="h-full w-full object-contain" alt="" />
                </div>
            </HomeCard>

            <HomeCard title={t("component.home.tutor.work.title")} className="w-1/2">
                <HomeCardDescription label={t("component.home.tutor.work.label")}>
                    {t("component.home.tutor.work.description")}
                </HomeCardDescription>
                <Link href={interpolate(Route.WORKSPACES, {})} passHref>
                    <Button
                        size="small"
                        className="self-end"
                        iconPosition="end"
                        icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                        title={t("component.home.tutor.work.cta.title")}
                        aria-label={t("component.home.tutor.work.cta.title")}
                    >
                        {t("component.home.tutor.work.cta.label")}
                    </Button>
                </Link>
                <div className="min-h-0 flex-1">
                    <img src="/static/home/collaborate.svg" className="h-full w-full object-contain" alt="" />
                </div>
            </HomeCard>
        </div>
    );
}
