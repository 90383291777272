import clsx from "clsx";
import { ReactNode, cloneElement, isValidElement } from "react";

import { Typography } from "@sol/uikit/core/Typography";

type HomeCardProps = {
    className?: string;
    title: ReactNode;
    image?: ReactNode;
    children: ReactNode;
};
export function HomeCard({ className, title, children, image }: HomeCardProps) {
    const hasImage = !!image;

    return (
        <section
            className={clsx(
                "rounded-medium bg-[var(--ant-color-bg-container)] px-6 py-4",
                "flex gap-4",
                hasImage ? "flex-row pb-0" : "flex-col",
                className,
            )}
        >
            <div className={clsx(hasImage ? "flex h-full w-2/5 flex-col gap-4 overflow-hidden" : "")}>
                <Typography tag="h1" variant="h4" className="font-bold text-[var(--ant-color-primary)]">
                    {title}
                </Typography>
                {hasImage && isValidElement(image) ? (
                    <div className="relative flex-1">
                        {cloneElement(image, {
                            className: clsx("absolute h-full w-full", image.props.className),
                        } as any)}
                    </div>
                ) : null}
            </div>
            <div className={clsx("flex flex-1 flex-col gap-4", hasImage ? "pb-4 pt-2" : "overflow-hidden")}>
                {children}
            </div>
        </section>
    );
}

type HomeCardDescriptionProps = {
    className?: string;
    label?: string;
    children?: ReactNode;
};

export function HomeCardDescription({ label, children, className }: HomeCardDescriptionProps) {
    return (
        <div className={clsx("flex flex-col gap-2", className)}>
            {label ? (
                <Typography tag="h2" variant="paragraph" className="!font-bold">
                    {label}
                </Typography>
            ) : null}
            {children ? (
                <Typography tag="p" variant="labelSmall" className="whitespace-pre-wrap">
                    {children}
                </Typography>
            ) : null}
        </div>
    );
}
