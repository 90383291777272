/* eslint-disable react/display-name */
import { useTranslation } from "react-i18next";
import { LearnerHome } from "src/components/home/LearnerHome";
import { ManagerHome } from "src/components/home/ManagerHome";
import { TrainerHome } from "src/components/home/TrainerHome";
import { TutorHome } from "src/components/home/TutorHome";

import { Feature, useCheckFeatureFlag } from "@sol/features";

import AnalyticsPage from "../components/AnalyticsPage";
import { PageLayout } from "../components/layouts/PageLayout";
import MetaTitle from "../components/MetaTitle";

const ClassroomHomepage = () => {
    const [t] = useTranslation();
    const checkFeatureFlag = useCheckFeatureFlag();

    return (
        <>
            <MetaTitle title={t("page.home.meta.title")} />
            <PageLayout title={t("page.home.title")} fluid={false}>
                {checkFeatureFlag(Feature.HOMEPAGE_TRAINER) && <TrainerHome />}
                {checkFeatureFlag(Feature.HOMEPAGE_LEARNER) && <LearnerHome />}
                {checkFeatureFlag(Feature.HOMEPAGE_MANAGER) && <ManagerHome />}
                {checkFeatureFlag(Feature.HOMEPAGE_TUTOR) && <TutorHome />}
                <AnalyticsPage />
            </PageLayout>
        </>
    );
};

export default ClassroomHomepage;
